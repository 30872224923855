import BaseAction from '@/actions/base_action.js'

export default class extends BaseAction {
  execute(properties, component) {
    // This lock does not guarantee atomicity when multi-user operations are involved,
    // but it helps ensure that nothing else is updating the view's data while the data
    // updating is still incomplete, which could cause flickering.
    $tpu.lock.acquire($tpu.keys.lckCardMove(), async () => {
      await this.moveScope(properties)
    }, (err, ret) => {
      // console.debug("Lock released", err, ret)
    })
  }

  async moveScope(properties) {
    const t = TrelloPowerUp.iframe()
    const formData = properties.formData
    const newIndex = formData.newIndex

    let scopeId
    try {
      scopeId = $tpu.keys.fromHtmScopeId(formData.scopeId)
    } catch(err) {
      console.error(err)
      return
    }

    await $tpu.r.projects.freshUpdate(properties.projectId, t, (project) => {
      this.constructor.changeScopeIndex(scopeId, newIndex, project)
    }, false)

    this.refreshProjectShow()
  }

  static changeScopeIndex(scopeId, newIndex, project) {
    const scopeCardIds = Array.from(project.scopeCardIds)
    scopeCardIds.remove(scopeId);
    scopeCardIds.splice(newIndex, 0, scopeId);
    project.scopeCardIds = scopeCardIds
  }
}
